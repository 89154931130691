export default {
  /**
   * SETTINGS
   */
  boxSettings: null,

  /**
  * BOX
  */
  boxSms: null,

  /**
  * GLOBAL LOADING - API
  */
  globalLoadingSms: false,

  /**
   * TRANSLATIONS - API
   */
  translationsSms: null,
  i18nDataSms: null,
};

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/Index.vue'),
  },
  {
    path: '/apps',
    name: 'apps',
    component: () => import('../views/Apps.vue'),
  },
  {
    path: '/sms-check-in',
    name: 'sms-check-in',
    component: () => import('../views/SmsCheckIn.vue'),
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('../views/Password.vue'),
  },
  {
    path: '/qr-code',
    name: 'qr-code',
    component: () => import('../views/Qrcode.vue'),
  },
];

export default routes;

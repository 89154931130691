// Add element-ui locale
import elementLocaleDE from 'element-ui/lib/locale/lang/de';
import elementLocaleEN from 'element-ui/lib/locale/lang/en';

import i18n from '../plugins/vue-i18n';

/* eslint no-underscore-dangle: ["error", { "allow": ["_vm"] }] */
export default {
  /**
   * SETTINGS
   */
  async setBoxSettings({ commit }) {
    try {
      const response = await this._vm.$http.get(`${process.env.VUE_APP_BASE_URL}/settings`, {
        headers: {
          'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
        },
      });
      commit('SET_BOX_SETTINGS', response.data);
      return true;
    } catch (e) {
      // localStorage.removeItem('auth');
      return e.status;
    }
  },

  /**
   * BOX
   */
  async setBoxSms({ commit }, { payload }) {
    const response = await this._vm.$http.get(`${process.env.VUE_APP_BASE_URL}/box`, {
      params: {
        id: payload.id,
      },
      headers: {
        'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
      },
    });
    try {
      commit('SET_BOX_SMS', response.data);
      return true;
    } catch (e) {
      console.log(e.status);
      return false;
    }
  },

  /**
   * GLOBAL LOADING
   */
  setGlobalLoadingSms({ commit }, status) {
    commit('SET_GLOBAL_LOADING_SMS', status);
  },

  /**
  * TRANSLATIONS
  */
  async setI18nDataSms({ commit }, payload) {
    // this._vm.$http.defaults.timeout = 18000;
    // this._vm.$http.defaults.timeoutErrorMessage = 'timeout';

    try {
      const response = await this._vm.$http({
        method: 'get',
        url: `${process.env.VUE_APP_BASE_URL}/resources`,
        params: {
          locale: payload,
        },
        headers: {
          'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
        },
      });

      // update language in localStorage + update store language
      const responseData = response.data;
      const translationsDE = {};
      const translationsEN = {};
      let translationsFinal = {};
      responseData.forEach((row) => {
        // if german
        if (row.localeID === 22) {
          translationsDE[row.code] = row.text;
        }
        // if english
        if (row.localeID === 352) {
          translationsEN[row.code] = row.text;
        }
      });
      // if german
      if (payload === 'de_AT') {
        translationsFinal = {
          ...translationsDE,
          ...elementLocaleDE,
        };
      }
      // if english
      if (payload === 'en_US') {
        translationsFinal = {
          ...translationsEN,
          ...elementLocaleEN,
        };
      }
      localStorage.removeItem(payload);
      localStorage.removeItem('lang');
      localStorage.setItem(payload, JSON.stringify(translationsFinal));
      localStorage.setItem('lang', payload);
      i18n.setLocaleMessage(localStorage.getItem('lang'), translationsFinal);
      commit('SET_I18N_SMS', response.data);
      return i18n;
    } catch (e) {
      return e.status;
    }
  },
};

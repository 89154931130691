export default {
  /**
   * SETTINGS
   */
  SET_BOX_SETTINGS(state, boxSettings) {
    state.boxSettings = boxSettings;
  },

  /**
   * BOX
   */
  SET_BOX_SMS(state, boxSms) {
    state.boxSms = boxSms;
  },

  /**
   * GLOBAL LOADING
   */
  SET_GLOBAL_LOADING_SMS(state, globalLoadingSms) {
    state.globalLoadingSms = globalLoadingSms;
  },

  /**
   * TRANSLATIONS
   */
  SET_I18N_SMS(state, i18nDataSms) {
    state.i18nDataSms = i18nDataSms;
  },
};
